const t = {
    icon: 'fa-users',
    menus: {
        main: {section: 'leads', priority: 30},
    },
    new: {
        attributes: [
            'broker', 'email', 'lastName', 'firstName', 'status','phone'
        ],
        content: [
            ['row', [
                ['block', [
                    '$broker!', '!$email!', '$lastName','$firstName','$phone'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'broker', 'email', 'lastName', 'firstName', 'status','phone'
        ],
        content: [
            ['row', [
                ['block', [
                    '$broker!', '!$email!', '$lastName','$firstName','$phone'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'broker', 'brokerCode', 'brokerFirstName', 'brokerLastName', 'code',  'firstName', 'lastName',
            'email','phone', 'createdAt', 'updatedAt', 'status'

        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        columns: [
            {id: 'brokerLastName', label: 'column_brokerlastname_label'},
            {id: 'brokerCode', label: 'column_brokercode_label'},
            {id: 'code', label: 'column_code_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'email', label: 'column_email_label'},
            {id: 'phone', label: 'column_phone_label'},
            {id: 'createdAt', label: 'column_createdat_label', format: (v: any) => v ? new Date(parseInt(v)).toLocaleString() : 'undefined'},
            {id: 'status', label: 'column_status_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;