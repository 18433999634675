const translations = {
    'fr-FR': {
        translation: {

            locale_fr_fr: 'Français (France)',
            locale_fr_lu: 'Français (Luxembourg)',
            locale_fr_mc: 'Français (Monaco)',
            locale_fr_be: 'Français (Belgique)',
            locale_fr_ca: 'Français (Canada)',
            locale_en_gb: 'Anglais (Grande Bretagne)',
            locale_en_us: 'Anglais (Etats-Unis)',
            locale_it_it: 'Italien (Italie)',
            locale_es_es: 'Espagnol (Espagne)',

            country_fr: 'France',
            country_lu: 'Luxembourg',
            country_mc: 'Monaco',
            country_be: 'Belgique',
            country_ca: 'Canada',
            country_gb: 'Grande Bretagne',
            country_us: 'Etats-Unis',
            country_it: 'Italie',
            country_es: 'Espagne',
            country_pt: 'Portugal',
            country_ch: 'Suisse',
            country_re: 'Réunion (Ile de)',

            field_matricule_label: 'Matricule',
            field_matricule_placeholder: 'Ex: M01A23',
            field_matricule_helper: 'Ex: M01A23',

            field_websiteurl_label: 'Site Internet',
            field_donationurl_label: 'Page internet de don',
            field_twitterurl_label: 'Page Twitter',
            field_facebookurl_label: 'Page Facebook',
            field_instagramurl_label: 'Page Instagram',
            field_linkedinurl_label: 'Page Linkedin',
            field_youtubeurl_label: 'Page Youtube',
            field_pinteresturl_label: 'Page Pinterest',
            field_tumblrurl_label: 'Page Tumblr',
            field_flickrurl_label: 'Page Flickr',
            field_redditurl_label: 'Page Reddit',
            field_tiktokurl_label: 'Page Tiktok',
            field_vimeourl_label: 'Page Vimeo',
            field_snapchaturl_label: 'Page Snapchat',

            field_websiteurl_placeholder: 'https://...',
            field_donationurl_placeholder: 'https://...',
            field_twitterurl_placeholder: 'https://...',
            field_facebookurl_placeholder: 'https://...',
            field_instagramurl_placeholder: 'https://...',
            field_linkedinurl_placeholder: 'https://...',
            field_youtubeurl_placeholder: 'https://...',
            field_pinteresturl_placeholder: 'https://...',
            field_tumblrurl_placeholder: 'https://...',
            field_flickrurl_placeholder: 'https://...',
            field_redditurl_placeholder: 'https://...',
            field_tiktokurl_placeholder: 'https://...',
            field_vimeourl_placeholder: 'https://...',
            field_snapchaturl_placeholder: 'https://...',
            
            field_websiteurl_helper: "Saisissez ici l'url du site internet",
            field_donationurl_helper: "Saisissez ici l'url de la page internet",
            field_twitterurl_helper: "Saisissez ici l'url de la page twitter",
            field_facebookurl_helper: "Saisissez ici l'url de la page facebook",
            field_instagramurl_helper: "Saisissez ici l'url de la page instagram",
            field_linkedinurl_helper: "Saisissez ici l'url de la page linkedin",
            field_youtubeurl_helper: "Saisissez ici l'url de la page youtube",
            field_pinteresturl_helper: "Saisissez ici l'url de la page pinterest",
            field_tumblrurl_helper: "Saisissez ici l'url de la page tumblr",
            field_flickrurl_helper: "Saisissez ici l'url de la page flickr",
            field_redditurl_helper: "Saisissez ici l'url de la page reddit",
            field_tiktokurl_helper: "Saisissez ici l'url de la page tiktok",
            field_vimeourl_helper: "Saisissez ici l'url de la page vimeo",
            field_snapchaturl_helper: "Saisissez ici l'url de la page snapchat",

            field_mooncardid_label: "Mooncard Id",
            field_externalid_label: 'ID externe',
            field_contract_label: 'Contrat',
            field_title_label : "Titre",
            field_title_placeholder : 'Ceci est un titre',
            field_title_helper : "Saisissez le titre ici",
            field_bannervideourl_label : "Vidéo bannière",
            field_bannervideourl_placeholder : 'https://...',
            field_bannervideourl_helper : "Saisissez l'url de la vidéo bannière",
            field_banner2videourl_label : 'Vidéo bannière secondaire',
            field_banner2videourl_placeholder : "https://...",
            field_banner2videourl_helper : "Saisissez l'url de l vidéo bannière secondaire",
            field_flyervideourl_label : "Vidéo flyer",
            field_flyervideourl_placeholder : "https://...",
            field_flyervideourl_helper : "Saisissez l'url de la vidéo flyer",
            field_mainvideourl_label : "Vidéo principale",
            field_mainvideourl_placeholder : 'https://...',
            field_mainvideourl_helper : "Saisissez l'url de la vidéo principale",
            field_mainshortvideourl_label : "Vidéo principale courte",
            field_mainshortvideourl_placeholder : 'https://...',
            field_mainshortvideourl_helper : "Saisissez l'url de la vidéo principale courte",
            field_mainlongvideourl_label : "Vidéo principale longue",
            field_mainlongvideourl_placeholder : 'https://...',
            field_mainlongvideourl_helper : "Saisissez l'url de la vidéo principale longue",
            field_backgroundvideourl_label : "Vidéo d'arrière plan",
            field_backgroundvideourl_placeholder : "https://...",
            field_backgroundvideourl_helper: "Saisissez l'url de la vidéo d'arrière plan",
            field_looservideourl_label : 'Vidéo perdant',
            field_looservideourl_placeholder : "https://...",
            field_looservideourl_helper: "Saisissez l'url de la vidéo perdant",
            field_participantvideourl_label : 'Vidéo participant',
            field_participantvideourl_placeholder : "https://...",
            field_participantvideourl_helper: "Saisissez l'url de la vidéo participant",
            field_promiseremindervideourl_label : 'Vidéo rappel de promesse',
            field_promiseremindervideourl_placeholder : "https://...",
            field_promiseremindervideourl_helper: "Saisissez l'url de la vidéo de rappel de promesse",
            field_promiselastremindervideourl_label : 'Vidéo de dernier rappel de promesse',
            field_promiselastremindervideourl_placeholder : "https://...",
            field_promiselastremindervideourl_helper: "Saisissez l'url de la vidéo de dernier rappel de promesse",
            field_salepaidvideourl_label : 'Vidéo vente payée',
            field_salepaidvideourl_placeholder : "https://...",
            field_salepaidvideourl_helper: "Saisissez l'url de la vidéo vente payée",
            field_salepromisedvideourl_label : 'Vidéo vente promesse',
            field_salepromisedvideourl_placeholder : "https://...",
            field_salepromisedvideourl_helper: "Saisissez l'url de la vidéo vente promesse",
            field_winnervideourl_label : 'Vidéo gagnant',
            field_winnervideourl_placeholder : "https://...",
            field_winnervideourl_helper: "Saisissez l'url de la vidéo gagnant",

            field_locale_label: 'Langue',
            field_locale_helper: 'Langue',
            field_locale_placeholder: 'Langue',

            field_features_label: 'Fonctionnalités',
            field_features_helper: 'Fonctionnalités',
            field_features_placeholder: 'Fonctionnalités',
            field_main_file: 'Fichier',
            field_country_label: 'Pays',
            field_country_helper: 'Pays',
            field_country_placeholder: 'Pays',
            field_broker_label: 'Apporteur d\'affaire',
            field_broker_helper: 'Apporteur d\'affaire',
            field_broker_placeholder: 'Sélectionnez un apporteur d\'affaire...',
            field_tenant_label: 'Tenant',
            field_employee_label: 'Porté',
            field_customer_label: 'Client',
            field_tenant_placeholder: 'Sélectionnez un tenant...',
            field_tenant_helper: "La tenant permet de cloisonner les données de la base",

            field_offer_label: "Offre",
            field_offer_placeholder: "Sélectionnez une offre",
            field_offer_helper: "L'offre permet de configurer les fonctionnalités proposées au client",

            field_partner_label: "Partenaire",
            field_partner_placeholder: "Sélectionnez le partenaire",
            field_partner_helper: "Le partenaire permet d'indiquer un contexte d'usage lié à un partenaire",

            field_campaign_template_label: "Modèle de page",
            field_campaign_template_placeholder: "...",
            field_campaign_template_helper: "Le modèle de page pourra aussi être surchargé dans le CMS",


            field_game_type_raffle: 'Tombola',
            field_game_type_donation: 'Collecte de dons',
            field_game_type_lottery: 'Loterie',
            field_game_type_dinner: 'Soirée avec tirage',

            field_ticketpriceamount_label: 'Prix du ticket (en centimes)',
            field_ticketpriceamount_placeholder: '100',
            field_ticketpriceamount_helper: "Prix d'un ticket de la collecte",

            field_game_planned_ticket_label: 'Nombre de tickets',
            field_game_planned_ticket_placeholder: '100',
            field_game_planned_ticket_helper: "Nombre de tickets de la collecte",

            field_game_planned_drawn_at_label: 'Date du tirage',
            field_game_planned_started_at_label: 'Date de lancement',
            field_game_planned_closed_at_label: 'Date de fin',

            field_mainquote_label: 'Citation',
            field_mainquote_placeholder: 'Citation...',
            field_mainquote_helper: 'Votre citation',

            field_publicphone_label: 'Téléphone public',
            field_publicphone_placeholder: '0102030405',
            field_publicphone_helper: 'Téléphone public',

            field_publicemail_label: 'Email public',
            field_publicemail_placeholder: 'contact@monasso.fr',
            field_publicemail_helper: 'Email public',

            field_notifyemail_label: 'Email de notifications',
            field_notifyemail_placeholder: 'contact@monasso.fr',
            field_notifyemail_helper: 'Email de notifications',

            field_overline_label: 'Accroche',
            field_overline_placeholder: 'Accroche...',
            field_overline_helper: 'Accroche',

            field_mainquotetitle_label: 'Titre',
            field_mainquotetitle_placeholder: 'Titre...',
            field_mainquotetitle_helper: 'Titre de votre citation',

            field_mainquoteimagetitle_label: "Titre d'image de citation",
            field_mainquoteimagetitle_placeholder: "Titre d'image de citation",
            field_mainquoteimagetitle_helper: "Titre d'image de citation",

            field_mainquoteimagesubtitle_label: "Sous-titre d'image de citation",
            field_mainquoteimagesubtitle_placeholder: "Sous-titre d'image de citation",
            field_mainquoteimagesubtitle_helper: "Sous-titre d'image de citation",

            field_appletouchimage_label: 'AppleTouch',
            field_appletouchimage_placeholder: 'image Apple Touch',
            field_appletouchimage_helper: "Image Apple Touch de la collecte",
            field_faviconimage_label: 'Favicon',
            field_faviconimage_placeholder: 'image de favicon',
            field_faviconimage_helper: "Favicon principal de la collecte",
            field_logoimage_label: 'Logo',
            field_logoimage_placeholder: 'image de logo',
            field_logoimage_helper: "Logo principal de la collecte",
            field_logoaltimage_label: 'Logo alternatif',
            field_logoaltimage_placeholder: 'image de logo alternatif',
            field_logoaltimage_helper: "Logo alternatif de la collecte",
            field_bannerimage_label: 'Bannière',
            field_bannerimage_placeholder: 'image de bannière',
            field_bannerimage_helper: "Bannière principale de la collecte",
            field_banner2image_label: 'Bannière secondaire',
            field_banner2image_placeholder: 'image de bannière secondaire',
            field_banner2image_helper: "Bannière secondaire de la collecte",
            field_twittershareimage_label: 'Image Twitter',
            field_twittershareimage_placeholder: 'image pour Twitter',
            field_twittershareimage_helper: "Image Twitter de la collecte",
            field_facebookshareimage_label: 'Image Facebook',
            field_facebookshareimage_placeholder: 'image pour Facebook',
            field_facebookshareimage_helper: "Image Facebook de la collecte",
            field_flyerimage_label: 'Flyer',
            field_flyerimage_placeholder: 'image de flyer',
            field_flyerimage_helper: "Flyer de la collecte",
            field_mainimage_label: 'Image principale',
            field_mainimage_placeholder: 'image principale',
            field_mainimage_helper: "Image principale",
            field_custompacks_label: 'Packs personnalisés',
            field_custompacks_placeholder: 'ex: 1,2,5*,10,25',
            field_custompacks_helper: "Packs personnalisés",
            field_custombonuses_label: 'Bonus personnalisés',
            field_custombonuses_placeholder: 'ex: 1|0,2|0,0|5*',
            field_custombonuses_helper: "Bonus personnalisés",
            field_customownerform_label: 'Formulaire acheteur personnalisé',
            field_customownerform_placeholder: '...',
            field_customownerform_helper: "Formulaire acheteur personnalisé",
            field_termspdffile_label: 'Règlement',
            field_termspdffile_placeholder: 'Règlement',
            field_termspdffile_helper: "Règlement",
            field_datafile_label : "Fichier de données",
            field_datafile_placeholder : 'Fichier de données',
            field_datafile_helper : 'Fichier de données',
            field_thumbnailimage_label: 'Image vignette',
            field_thumbnailimage_placeholder: 'image vignette',
            field_thumbnailimage_helper: "Image vignette",
            field_desktopimage_label: 'Image desktop',
            field_desktopimage_placeholder: 'image desktop',
            field_desktopimage_helper: "Image desktop de la collecte",
            field_tabletimage_label: 'Image tablette',
            field_tabletimage_placeholder: 'image tablette',
            field_tabletimage_helper: "Image tablette de la collecte",
            field_mobileimage_label: 'Image mobile',
            field_mobileimage_placeholder: 'image mobile',
            field_mobileimage_helper: "Image mobile de la collecte",
            field_tvimage_label: 'Image tv',
            field_tvimage_placeholder: 'image tv',
            field_tvimage_helper: "Image tv de la collecte",
            field_emailimage_label: 'Image email',
            field_emailimage_placeholder: 'image email',
            field_emailimage_helper: "Image email de la collecte",
            field_backgroundimage_label: "Image d'arrière plan",
            field_backgroundimage_placeholder: "image d'arrière plan",
            field_backgroundimage_helper: "Image d'arrière plan de la collecte",
            field_mainquoteimage_label: 'Citation',
            field_mainquoteimage_placeholder: 'image de citation',
            field_mainquoteimage_helper: "Image illustrant votre ciation",
            field_fullname_label: 'Nom complet',
            field_fullname_placeholder: 'Nom complet',
            field_fullname_helper: "Nom complet",
            field_shortname_label: 'Nom court',
            field_shortname_placeholder: 'Nom court',
            field_shortname_helper: "Nom court",
            field_legalname_label: 'Nom légal',
            field_legalname_placeholder: 'Nom légal',
            field_legalname_helper: "Nom légal",
            field_nin_label: "Numéro national d'identification",
            field_nin_placeholder: "Numéro national d'identification",
            field_nin_helper: "Numéro national d'identification",
            field_mainvideofile_label: 'Vidéo principale',
            field_mainvideofile_placeholder: 'vidéo principale',
            field_mainvideofile_helper: "Vidéo principale",
            field_mainaudiofile_label: 'Audio principale',
            field_mainaudiofile_placeholder: 'audio principale',
            field_mainaudiofile_helper: "Audio principale",

            field_locationname_label: 'Nom du lieu',
            field_locationname_placeholder: 'Nom du lieu',
            field_locationname_helper: "Nom du lieu",

            field_locationlatitude_label: 'Latitude du lieu',
            field_locationlatitude_placeholder: 'Latitude du lieu',
            field_locationlatitude_helper: "Latitude du lieu",

            field_locationmainaudiourl_label: 'Bande son audio principale du lieu',
            field_locationmainaudiourl_placeholder: 'Bande son audio principale du lieu',
            field_locationmainaudiourl_helper: "Bande son audio principale du lieu",

            field_locationmainvideourl_label: 'Vidéo principale du lieu',
            field_locationmainvideourl_placeholder: 'Vidéo principale du lieu',
            field_locationmainvideourl_helper: "Vidéo principale du lieu",

            field_locationmainimage_label: 'Image principale du lieu',
            field_locationmainimage_placeholder: 'Image principale du lieu',
            field_locationmainimage_helper: "Image principale du lieu",

            field_agenda_label: 'Programme',
            field_agenda_placeholder: 'Programme',
            field_agenda_helper: "Programme",

            field_infos_label: 'Informations',
            field_infos_placeholder: 'Informations',
            field_infos_helper: "Informations",

            field_warning_label: 'Avertissement',
            field_warning_placeholder: 'Avertissement',
            field_warning_helper: "Avertissement",

            field_workedday_label: "Jours travaillés",

            field_locationlogoimage_label: 'Logo du lieu',
            field_locationlogoimage_placeholder: 'Logo du lieu',
            field_locationlogoimage_helper: "Logo du lieu",

            field_locationphone_label: 'Téléphone du lieu',
            field_locationphone_placeholder: 'Téléphone du lieu',
            field_locationphone_helper: "Téléphone du lieu",

            field_locationemail_label: 'Email du lieu',
            field_locationemail_placeholder: 'Email du lieu',
            field_locationemail_helper: "Email du lieu",

            field_locationstate_label: 'Etat/Province du lieu',
            field_locationstate_placeholder: 'Etat/Province du lieu',
            field_locationstate_helper: "Etat/Province du lieu",

            field_locationstreet_label: 'Rue du lieu',
            field_locationstreet_placeholder: 'Rue du lieu',
            field_locationstreet_helper: "Rue du lieu",

            field_locationzipcode_label: 'Code postal du lieu',
            field_locationzipcode_placeholder: 'Code postal du lieu',
            field_locationzipcode_helper: "Code postal du lieu",

            field_locationwebsiteurl_label: 'Site internet du lieu',
            field_locationwebsiteurl_placeholder: 'Site internet du lieu',
            field_locationwebsiteurl_helper: "Site internet du lieu",

            field_locationfacebookurl_label: 'Page Facebook du lieu',
            field_locationfacebookurl_placeholder: 'Page Facebook du lieu',
            field_locationfacebookurl_helper: "Page Facebook du lieu",

            field_locationtwitterurl_label: 'Page Twitter du lieu',
            field_locationtwitterurl_placeholder: 'Page Twitter du lieu',
            field_locationtwitterurl_helper: "Page Twitter du lieu",

            field_locationyoutubeurl_label: 'Page YouTube du lieu',
            field_locationyoutubeurl_placeholder: 'Page YouTube du lieu',
            field_locationyoutubeurl_helper: "Page YouTube du lieu",

            field_locationlongitude_label: 'Longitude',
            field_locationlongitude_placeholder: 'Longitude',
            field_locationlongitude_helper: "Longitude",

            field_locationcountry_label: 'Pays du lieu',
            field_locationcountry_placeholder: 'Pays du lieu',
            field_locationcountry_helper: "Pays du lieu",

            field_locationcomplement_label: "Complément d'adresse du lieu",
            field_locationcomplement_placeholder: "Complément d'adresse du lieu",
            field_locationcomplement_helper: "Complément d'adresse du lieu",

            field_locationcity_label: 'Ville du lieu',
            field_locationcity_placeholder: 'Ville du lieu',
            field_locationcity_helper: "Ville du lieu",

            field_locationdescription_label: 'Description du lieu',
            field_locationdescription_placeholder: 'Description du lieu',
            field_locationdescription_helper: "Description du lieu",

            field_locationpresentation_label: 'Présentation du lieu',
            field_locationpresentation_placeholder: 'Présentation du lieu',
            field_locationpresentation_helper: "Présentation du lieu",

            field_godfathertitle_label: 'Intitulé du parrain',
            field_godfathertitle_placeholder: 'Intitulé du parrain',
            field_godfathertitle_helper: "Intitulé du parrain",

            field_godfathersubtitle_label: 'Sous-intitulé du parrain',
            field_godfathersubtitle_placeholder: 'Sous-intitulé du parrain',
            field_godfathersubtitle_helper: "Sous-intitulé du parrain",

            field_godfathervideourl_label: 'Vidéo du parrain',
            field_godfathervideourl_placeholder: 'Vidéo du parrain',
            field_godfathervideourl_helper: "Vidéo du parrain",

            field_godfatherimage_label: 'Image du parrain',
            field_godfatherimage_placeholder: 'Image du parrain',
            field_godfatherimage_helper: "Image du parrain",

            field_godfather2title_label: 'Intitulé du parrain 2',
            field_godfather2title_placeholder: 'Intitulé du parrain 2',
            field_godfather2title_helper: "Intitulé du parrain 2",

            field_godfather2subtitle_label: 'Sous-intitulé du parrain 2',
            field_godfather2subtitle_placeholder: 'Sous-intitulé du parrain 2',
            field_godfather2subtitle_helper: "Sous-intitulé du parrain 2",

            field_godfather2videourl_label: 'Vidéo du parrain 2',
            field_godfather2videourl_placeholder: 'Vidéo du parrain 2',
            field_godfather2videourl_helper: "Vidéo du parrain 2",

            field_godfather2image_label: 'Image du parrain 2',
            field_godfather2image_placeholder: 'Image du parrain 2',
            field_godfather2image_helper: "Image du parrain 2",

            field_godfather3title_label: 'Intitulé du parrain 3',
            field_godfather3title_placeholder: 'Intitulé du parrain 3',
            field_godfather3title_helper: "Intitulé du parrain 3",

            field_godfather3subtitle_label: 'Sous-intitulé du parrain 3',
            field_godfather3subtitle_placeholder: 'Sous-intitulé du parrain 3',
            field_godfather3subtitle_helper: "Sous-intitulé du parrain 3",

            field_godfather3videourl_label: 'Vidéo du parrain 3',
            field_godfather3videourl_placeholder: 'Vidéo du parrain 3',
            field_godfather3videourl_helper: "Vidéo du parrain 3",

            field_godfather3image_label: 'Image du parrain 3',
            field_godfather3image_placeholder: 'Image du parrain 3',
            field_godfather3image_helper: "Image du parrain 3",

            field_image_dirty_label: "Attention: cette image n'a pas encore été sauvegardé",

            field_organization_label: "Organisation",
            field_organization_placeholder: "Sélectionnez l'organisation",
            field_organization_helper: "L'organisation",

            field_godfather_label: "Parrain référent",
            field_godfather_placeholder: "Sélectionnez le parrain",
            field_godfather_helper: "Sélectionnez ici la référence du parrain",

            field_godfather_phone_label: "Téléphone du parrain",
            field_godfather_phone_placeholder: "Téléphone du parrain",
            field_godfather_phone_helper: "Numéro de téléphone du parrain",

            field_godfather_email_label: "Email du parrain",
            field_godfather_email_placeholder: "Email du parrain",
            field_godfather_email_helper: "Email du parrain",

            field_godfather_status_label: "Statut du compte du parrain",
            field_godfather_status_placeholder: "Etat du parrain",
            field_godfather_status_helper: "Indication de l'état du parrain",

            field_beneficiary_label: "Bénéficiaire",
            field_beneficiary_placeholder: "Sélectionnez un bénéficiaire",
            field_beneficiary_helper: "Ajouter un bénéficiaire",

            field_project_label: "Projet",
            field_project_placeholder: "Sélectionnez un projet",
            field_project_helper: "Ajouter un projet parrain",

            field_keyword_status_label: 'Status',
            field_keyword_status_placeholder: 'Status',
            field_keyword_status_helper: 'Status',

            field_owner_label: "Propriétaire",
            field_owner_placeholder: "Propriétaire",
            field_owner_helper: "Propriétaire",

            field_sale_label: "Ventes",
            field_sale_placeholder: "Sélectionnez la vente",
            field_sale_helper: "Référence de la vente",

            field_game_sale_mandatory_opt_in_message_label: "Message d'accord",
            field_game_sale_mandatory_opt_in_message_placeholder: "Message d'accord",
            field_game_sale_mandatory_opt_in_message_helper: "Message d'accord",

            field_game_label: 'Collecte',
            field_game_placeholder: 'Collecte',
            field_game_helper: 'Sélectionnez la collecte associée',

            field_offer_params_label: "Params",
            field_offer_params_placeholder: "Insert JSON text here...",
            field_offer_params_helper: "Params",

            field_integration_params_label: "Params",
            field_integration_params_placeholder: "Insert JSON text here...",
            field_integration_params_helper: "Params",

            field_module_params_label: "Params",
            field_module_params_placeholder: "Insert JSON text here...",
            field_module_params_helper: "Params",

            field_partner_status_label: "Statut",
            field_partner_status_placeholder: "Statut...",
            field_partner_status_helper: "Statut",

            field_product_status_label: "Statut",
            field_product_status_placeholder: "Statut...",
            field_product_status_helper: "Statut",

            field_currency_label: "Devise",
            field_currency_placeholder: "Devise",
            field_currency_helper: "Devise",

            field_price_currency_label: "Devise",
            field_price_currency_placeholder: "Devise",
            field_price_currency_helper: "Devise",

            field_ticketpricecurrency_label: "Devise",
            field_ticketpricecurrency_placeholder: "Devise",
            field_ticketpricecurrency_helper: "Devise",

            field_price_amount_label: "Montant",
            field_price_amount_placeholder: "Montant",
            field_price_amount_helper: "Montant",

            field_user_label: "Utilisateur",
            field_user_placeholder: "Sélectionnez un utilisateur",
            field_user_helper: "Utilisateur associé",

            field_key_label: "Key",
            field_key_placeholder: "Key",
            field_key_helper: "Key",

            field_kind_label: "Kind",
            field_kind_placeholder: "Kind",
            field_kind_helper: "Kind",

            field_rank_label: "Rang",
            field_rank_helper: "Rang",

            field_customcode_label: "Code personnalisé",
            field_customcode_helper: "Code personnalisé",

            field_planneddonationratio_label: "Pourcentage de don",
            field_planneddonationratio_helper: "Pourcentage de don",

            field_data_label: "Data",
            field_data_placeholder: "Data",
            field_data_helper: "Data",

            field_params_label: "Paramètres",
            field_params_placeholder: "Paramètres",
            field_params_helper: "Paramètres",

            field_report_label: "Report",
            field_report_placeholder: "Report",
            field_report_helper: "Report",

            field_domain_label: "Domaine",
            field_domain_placeholder: "Domaine",
            field_domain_helper: "Domaine",

            field_datestart_label: "Début",
            field_dateend_label: "Fin",
            field_startcallbackurl_label: "Start callback url",
            field_startcallbackurl_placeholder: "Start callback url",
            field_startcallbackurl_helper: "Start callback url",

            field_completecallbackurl_label: "Complete callback url",
            field_completecallbackurl_placeholder: "Complete callback url",
            field_completecallbackurl_helper: "Complete callback url",

            field_errorcallbackurl_label: "Error callback url",
            field_errorcallbackurl_placeholder: "Error callback url",
            field_errorcallbackurl_helper: "Error callback url",

            field_abortcallbackurl_label: "Abort callback url",
            field_abortcallbackurl_placeholder: "Abort callback url",
            field_abortcallbackurl_helper: "Abort callback url",

            field_sender_label: "Expéditeur",
            field_sender_placeholder: "Expéditeur",
            field_sender_helper: "Expéditeur",
            field_recipient_label: "Destinataire",
            field_recipient_placeholder: "Destinataire",
            field_recipient_helper: "Destinataire",
            field_template_label: "Template",
            field_template_placeholder: "Template",
            field_template_helper: "Template",
            field_variables_label: "Variables",
            field_variables_placeholder: "Variables",
            field_variables_helper: "Variables",
            field_bodyfile_label: "Body",
            field_bodyfile_placeholder: "Body",
            field_bodyfile_helper: "Body",
            field_bodytextfile_label: "Body Text",
            field_bodytextfile_placeholder: "Body Text",
            field_bodytextfile_helper: "Body Text",
            field_subject_label: "Subjects",
            field_subject_placeholder: "Subjects",
            field_subject_helper: "Subjects",
            field_attachments_label: "Attachments",
            field_attachments_placeholder: "Attachments",
            field_attachments_helper: "Attachments",
            type_email_label: "Email",
            type_sms_label: "SMS",
            type_push_label: "Push",
            field_values_label: "Valeurs",
            field_result_label: "Résultat",
            field_draft_label: 'Draft',
            field_strategy_label: 'Stratégie',
            field_strategy_helper: 'Stratégie',
            field_strategy_placeholder: 'Stratégie',
            field_status_label: 'Statut',
            field_status_helper: 'Statut',
            field_status_placeholder: 'Statut',
            field_bunchrank_label: 'Rang du lot',
            field_bunchrank_helper: 'Rang du lot',
            field_bunch_label: 'Lot',
            field_bunch_helper: 'Lot',
            field_ticket_label: 'Ticket',
            field_ticket_helper: 'Ticket',
            field_draw_label: 'Tirage',
            field_draw_placeholder: 'Sélectionnez un tirage',
            field_draw_helper: 'Tirage',
            field_promise_label: 'Promesse',
            field_promise_placeholder: 'Sélectionnez une promesse',
            field_promise_helper: 'Promesse',
            field_rate_label: "TJM",
            field_rate_helper: "Tarif journalier",
            field_autostart_label: "Démarrage automatique",
            field_period_percent_label: "Frais de gestion sur la période",
            field_period_turnover_label: "Field_period_turnover_label",
            field_salary_label: "Salaire ",
            field_autodelete_label: "Suppression automatique",
            field_baseaccount_label: "Field_baseaccount_label",
            field_featured_label: "En vedette",
            field_peramount_label: "Montant du PER",
            Field_peeamount_label: "Montant du PEE",
            field_employer_contributions_label: "Charges Patronales",
            field_employee_contributions_label: "Charges Salariales",
            field_bunch_tags_label: "Tags",
            field_bunch_tags_helper: "Tags",
            field_EndCustomerName_label: "Client Final",
            field_tags_label: "Tags",
            field_tags_helper: "Tags",

            field_maincss_label: "CSS",
            field_maincss_helper: "CSS",

            field_mainjs_label: "JS",
            field_mainjs_helper: "JS",

            field_maintheme_label: 'Thème principal',
            field_maintheme_placeholder : 'thème principal',
            field_maintheme_helper : 'Selectionnez le thème principal',

            field_organization_module: 'Module',
            field_organization_params: 'Params',


            field_sponsoring_label: "Partenariat",
            field_sponsoring_helper: "Sélectionner le partenariat",

            searchbar_placeholder: "Rechercher...",
            searchbar_invalid_input: "Code invalide",

            field_varcolorprimary_label: 'Principale',
            field_varcolorsecondary_label: 'Secondaire',
            field_varcolornavprimary_label: 'Principale Nav',
            field_varcolornavsecondary_label: 'Secondaire Nav',
            field_varcolorpanelprimary_label: 'Principale Panels',
            field_varcolorpanelsecondary_label: 'Secondaire Panels',
            field_varcolorchoiceprimary_label: 'Principale Choix',
            field_varcolorchoicesecondary_label: 'Secondaire Choix',
            field_varcolorfooterprimary_label: 'Principale Footer',
            field_varcolorfootersecondary_label: 'Secondaire Footer',
            field_varcolorheading_label: 'Principale Headings',
            field_varcolortitle_label: 'Principale Titres',
            field_varcolorlogo_label: 'Secondaire Logo',

            game_screen_general_title: 'Général',
            game_screen_desktop_title: 'Desktop',
            notification_screen_general_title: 'Général',
            notification_screen_desktop_title: 'Desktop',

            edit_game_form_general_title: 'Général',
            edit_game_form_style_title: 'Style',
            edit_game_form_code_title: 'Code',
            edit_game_form_presentation_title: 'Présentation',
            edit_game_form_fundraising_title: 'Collecte',
            edit_game_form_images_title: 'Images',
            edit_game_form_parameters_title: 'Paramètres',
            edit_game_form_advanced_title: 'Avancé',
            edit_game_form_videos_title: 'Vidéos',
            edit_game_form_location_title: 'Lieu',
            edit_game_form_godfather_title: 'Parrains',
            edit_theme_form_advanced_title: 'Avancé',
            edit_theme_form_colors_title: 'Couleurs',

            edit_organization_form_presentation_title: 'Présentation',
            edit_organization_form_location_title: 'Coordonnées',
            edit_organization_form_images_title: 'Images',
            edit_organization_form_socials_title: 'Réseaux Sociaux',
            edit_organization_form_videos_title: 'Vidéos',
            edit_organization_form_parameters_title: 'Paramètres',
            edit_organization_form_advanced_title: 'Avancé',

            column_brokerlastname_label: 'Broker',
            column_brokercode_label: 'Code Broker',
            column_role_label: 'Rôle',
            column_progress_label: 'Progression',
            column_id_label: 'Identifiant',
            column_bunch_label: 'Lots',
            column_date_label: 'Date',
            column_datestart_label: 'Date de début',
            column_dateend_label: 'Date de fin',
            column_draft_label: 'Draft',
            column_email_label: 'Email',
            column_employee_label: 'Porté',
            column_mainFile_label: 'Bulletin',
            column_name_label: 'Nom',
            column_lastname_label: 'Nom de famille',
            column_firstname_label: 'Prénom',
            column_status_label: 'Statut',
            column_jobstatus_label: 'Statut du job',
            column_organization_label: 'Organisation',
            column_organization_name_label: 'Organisation',
            column_game_name_label: 'Collecte',
            column_externalid_label:'ID externe',
            column_tickets_label: 'Tickets',
            column_ticket_label: 'Ticket',
            column_game_planned_tickets_label: 'Tickets Max',
            column_price_label: 'Prix',
            column_cost_label: 'Coût',
            column_vatCost_label:'TVA',
            column_vatPercent_label:'%TVA',
            column_currency_label: 'Devise',
            column_customer_label: 'Client',
            column_customercode_label: 'Code Client',
            column_project_label: 'Projet',
            column_employeename_label: 'Porté',
            column_amount_label: 'Montant',
            column_createdat_label: 'Date de création',
            column_description_label: 'Description',
            column_updatedat_label: 'Date de modification',
            column_workedday_label: 'Jours travaillés',
            column_actions_label: 'Actions',
            column_code_label: 'Code',
            column_game_code_label: 'Collecte',
            column_organization_code_label: 'Organisation',
            column_key_label: 'Key',
            column_keywords_label: 'Mots Clefs',
            column_game_label: 'Collecte',
            column_phone_label: 'Téléphone',
            column_payment_amount_label: 'Montant',
            column_payment_currency_label: 'Devise',
            column_donation_label: 'Don',
            column_owner_label: 'Propriétaire',
            column_owneremail_label: 'Email',
            column_game_stat_sales_label: 'Ventes',
            column_game_stat_tickets_label: 'Tickets Emis',
            column_type_label: 'Type',
            column_offer_label: 'Offre',
            column_partner_label: 'Partenaire',
            column_tenant_label: 'Tenant',
            column_tenantname_label: 'Nom du tenant',
            column_tenantcode_label: 'Code du tenant',
            column_url_label: 'URL',
            column_redirect_public_url_label: 'URL',
            column_redirect_url_label: 'Cible',
            column_main_image_label: "Image Principale",
            column_mooncardId_label:'Mooncard Id',
            column_rank_label: "Rang",
            column_strategy_label: 'Stratégie',
            column_quantity_label: "Quantité",
            column_game_stat_paid_sales_label: 'Ventes payées',
            column_game_stat_paid_amount_label: 'Montant payé',
            column_overline_label: 'Overline',
            column_sellergroup_code_label: 'Groupe de vendeurs',
            column_sender_label: 'Expéditeur',
            column_rate_label: 'TJM',
            column_recipient_label: 'Destinataire',
            column_subject_label: 'Subject',
            column_prizes_label: 'Prix',
            column_bunches_label: 'Lots',
            column_result_label: 'Resultat',
            column_values_label: 'Valeurs',
            column_winners_label: 'Gagnants',

            column_stataccountamount_label: 'Compte de reserve',
            column_contract_label: 'Contrat',
            screen_employee_new_label: 'Nouvel employé',
            screen_employee_label: 'Employé',
            screen_expenses_label: 'Dépenses',
            screen_files_label:"Documents",
            screen_file_new_label:"Nouveau Document",
            screen_file_label:"Document",
            screen_broker_label: 'Apporteur d\'affaire',
            screen_brokers_label: 'Apporteurs d\'affaire',
            screen_broker_new_label: 'Nouvel Apporteur d\'affaire',
            screen_broker_edit_label:'Modification de l\'apporteur d\'affaire',
            screen_employee_edit_label: 'Modification de l\'employé',
            screen_employees_label: 'Employés',
            screen_expense_label: 'Dépense',
            screen_customer_label: 'Entreprise Cliente',
            screen_customer_new_label: 'Nouvelle entreprise cliente',
            screen_customer_edit_label: 'Modification de l\'entreprise cliente',
            screen_customers_label: 'Entreprises Clientes',
            screen_payslips_label: 'Bulletins de Salaire',
            screen_payslip_label: 'Bulletin de Salaire',
            screen_payslip_new_label: 'Nouveau Bulletin de Salaire',
            screen_payslip_edit_label: 'Modification du Bulletin de Salaire',
            screen_contract_label: 'Contrat {{id}}',
            screen_contract_new_label: 'Nouveau contrat',
            screen_contract_edit_label: "Modification du contrat",
            screen_contracts_label: 'Contrats',
            screen_cinvoices_label:'Factures',
            screen_cinvoice_label:'Facture',
            screen_cinvoice_new_label:'Nouvelle Facture',
            screen_cinvoice_edit_label:'Modification de la facture',
            screen_eperiod_new_label: 'Nouvelle Période',
            screen_cpayment_new_label:'Nouveau Paiement',
            screen_cpayment_label:'Paiement',
            screen_cpayments_label:'Paiements',
            screen_cpayment_edit_label:'Modification du Paiement',

            screen_cperiod_new_label:'Nouvelle Période',
            screen_cperiod_label:'Période',
            screen_cperiods_label:'Périodes',
            screen_eperiods_label:'Périodes Salariales',
            screen_eperiod_edit_label: 'Modification d\'une période salarial',
            screen_cperiod_edit_label:'Modification de la Période',
            screen_eperiod_label: 'E Période',

            screen_home_label: 'Accueil',
            screen_campaign_label: 'Campagne {{id}}',
            screen_campaign_new_label: 'Nouvelle campagne',
            screen_campaign_edit_label: 'Modification de la campagne',
            screen_campaigns_label: 'Campagnes',
            screen_configuration_label: 'Configuration {{id}}',
            screen_configuration_new_label: 'Nouvelle configuration',
            screen_configuration_edit_label: 'Modification de la configuration',
            screen_configurations_label: 'Configurations',
            screen_definition_label: 'Définition {{id}}',
            screen_definition_new_label: 'Nouvelle définition',
            screen_definition_edit_label: 'Modification de la définition',
            screen_definitions_label: 'Definitions',
            screen_feature_label: 'Feature',
            screen_feature_new_label: 'Nouvelle feature',
            screen_feature_edit_label: 'Modification de la feature',
            screen_features_label: 'Features',
            screen_game_label: 'Collecte {{id}}',
            screen_game_new_label: 'Nouvelle collecte',
            screen_game_edit_label: 'Modification de la collecte',
            screen_games_label: 'Collectes',
            screen_gimport_label: 'Import {{id}}',
            screen_gimport_new_label: 'Nouvel import',
            screen_gimport_edit_label: "Modification de l'import",
            screen_gimports_label: 'Imports',
            screen_godfather_label: 'Parrain {{id}}',
            screen_godfather_new_label: 'Nouveau parrain',
            screen_godfather_edit_label: 'Modification du parrain',
            screen_godfathers_label: 'Parrains',
            screen_integration_label: 'Intégration {{id}}',
            screen_integration_new_label: 'Nouvelle intégration',
            screen_integration_edit_label: "Modification de l'intégration",
            screen_integrations_label: 'Intégrations',
            screen_keyword_label: 'Mots clef {{id}}',
            screen_keyword_new_label: 'Nouveau mot-clé',
            screen_keyword_edit_label: 'Modification du mot-clé',
            screen_keywords_label: 'Mots clefs',
            screen_beneficiary_label: 'Bénéficiaire {{id}}',
            screen_beneficiary_new_label: 'Nouveau bénéficiaire',
            screen_beneficiary_edit_label: 'Modification du bénéficiaire',
            screen_beneficiaries_label: 'Bénéficiaires',
            screen_project_label: 'Projet {{id}}',
            screen_project_new_label: 'Nouveau projet',
            screen_project_edit_label: 'Modification du projet',
            screen_projects_label: 'Projets',
            screen_bunch_label: 'Lot {{id}}',
            screen_bunch_new_label: 'Nouveau lot',
            screen_bunch_edit_label: 'Modification du lot',
            screen_bunches_label: 'Lots',
            screen_sponsoring_label: 'Partenariat {{id}}',
            screen_sponsoring_new_label: 'Nouveau partenariat',
            screen_sponsoring_edit_label: 'Modification du partenariat',
            screen_sponsorings_label: 'Partenariats',
            screen_input_label: 'Champ {{id}}',
            screen_input_new_label: 'Nouveau champ',
            screen_input_edit_label: 'Modification du champ',
            screen_inputs_label: 'Champs',
            screen_pack_label: 'Pack {{id}}',
            screen_pack_new_label: 'Nouveau pack',
            screen_pack_edit_label: 'Modification du pack',
            screen_packs_label: 'Packs',
            screen_lead_label: 'Lead {{id}}',
            screen_lead_new_label: 'Nouveau Lead',
            screen_lead_edit_label: "Modification de lead",
            screen_leads_label: 'Leads',
            screen_notification_label: 'Notification',
            screen_notification_new_label: 'Nouvelle notification',
            screen_notification_edit_label: 'Modification de la notification',
            screen_notifications_label: 'Notifications',
            screen_module_label: 'Module {{id}}',
            screen_module_new_label: 'Nouveau module',
            screen_module_edit_label: 'Modification du module',
            screen_modules_label: 'Modules',
            screen_offer_label: 'Offre {{id}}',
            screen_offer_new_label: 'Nouvelle offre',
            screen_offer_edit_label: "Modification de l'offre",
            screen_offers_label: 'Offres',
            screen_order_label: 'Commande {{id}}',
            screen_order_new_label: 'Nouvelle commande',
            screen_order_edit_label: 'Modification de la commande',
            screen_orders_label: 'Commandes',
            screen_organization_label: 'Organisation {{id}}',
            screen_organization_new_label: 'Nouvelle organisation',
            screen_organization_edit_label: "Modification de l'organisation",
            screen_organizations_label: 'Organisations',
            screen_partner_label: 'Partenaire {{id}}',
            screen_partner_new_label: 'Nouveau partenaire',
            screen_partner_edit_label: 'Modification du partenaire',
            screen_partners_label: 'Partenaires',
            screen_product_label: 'Produit {{id}}',
            screen_product_new_label: 'Nouveau produit',
            screen_product_edit_label: 'Modification du produit',
            screen_products_label: 'Produits',
            screen_promise_label: 'Promesse {{id}}',
            screen_promise_new_label: 'Nouvelle promesse',
            screen_promise_edit_label: 'Modification de la promesse',
            screen_promises_label: 'Promesses',
            screen_purchaser_label: 'Participant {{id}}',
            screen_purchaser_new_label: 'Nouvelle participant',
            screen_purchaser_edit_label: 'Modification du participant',
            screen_purchasers_label: 'Participants',
            screen_redirect_label: 'Redirection {{id}}',
            screen_redirect_new_label: 'Nouvelle redirection',
            screen_redirect_edit_label: 'Modification de la redirection',
            screen_redirects_label: 'Redirections',
            screen_reminder_label: 'Rappel {{id}}',
            screen_reminder_new_label: 'Nouveau rappel',
            screen_reminder_edit_label: 'Modification du rappel',
            screen_reminders_label: 'Rappels',
            screen_greminder_label: 'Rappel de collecte {{id}}',
            screen_greminder_new_label: 'Nouveau rappel de collecte',
            screen_greminder_edit_label: 'Modification du rappel de collecte',
            screen_greminders_label: 'Rappels de collecte',
            screen_oreminder_label: "Rappel d'organisation {{id}}",
            screen_oreminder_new_label: "Nouveau rappel d'organisation",
            screen_oreminder_edit_label: "Modification du rappel d'organisation",
            screen_oreminders_label: "Rappels d'organisation",
            screen_preminder_label: 'Rappel de partenaire {{id}}',
            screen_preminder_new_label: 'Nouveau rappel de partenaire',
            screen_preminder_edit_label: 'Modification du rappel de partenaire',
            screen_preminders_label: 'Rappels de partenaire',
            screen_treminder_label: 'Rappel de tenant {{id}}',
            screen_treminder_new_label: 'Nouveau rappel de tenant',
            screen_treminder_edit_label: 'Modification du rappel de tenant',
            screen_treminders_label: 'Rappels de tenant',
            screen_sale_label: 'Vente {{id}}',
            screen_sale_new_label: 'Nouvelle vente',
            screen_sale_edit_label: 'Modification de la vente {{id}}',
            screen_sales_label: 'Ventes',
            screen_simulations_label: 'Simulations',
            screen_simulation_new_label:'Nouvelle simulation',
            screen_simulation_edit_label:'Modification de la simulation',
            screen_tag_label: 'Tag {{id}}',
            screen_tag_new_label: 'New tag',
            screen_tag_edit_label: 'Update tag',
            screen_tags_label: 'Tags',
            screen_tenant_label: 'Tenant {{id}}',
            screen_tenant_new_label: 'Nouveau tenant',
            screen_tenant_edit_label: 'Modification du tenant',
            screen_tenants_label: 'Tenants',
            screen_theme_label: 'Thème {{id}}',
            screen_theme_new_label: 'Nouveau theme',
            screen_theme_edit_label: 'Modifier le theme',
            screen_themes_label: 'Thèmes',
            screen_ticket_label: 'Ticket {{id}}',
            screen_ticket_new_label: 'Nouveau ticket',
            screen_ticket_edit_label: 'Modification du ticket',
            screen_tickets_label: 'Tickets',
            screen_user_label: 'Utilisateur {{id}}',
            screen_user_new_label: 'Nouvel utilisateur',
            screen_user_edit_label: "Modification de l'utilisateur",
            screen_user_profile_label: 'Mon compte',
            screen_users_label: 'Utilisateurs',
            screen_simulation_label: 'Simulations',
            
            screen_generic_list_table_empty: 'Aucun élément disponible.',
            screen_users_list_table_empty: 'Aucun utilisateur disponible.',

            status_created_label: 'Crée',
            status_ongoing_label: 'En cours',
            status_renewed_label: 'Renouvelé',
            status_expires_label: 'Expire bientôt',
            status_completed_label: 'Fini',
            status_locked_label: 'Archivé',

            menu_section_marketing_label: 'Marketing',
            menu_section_customers_label: 'Clients',
            menu_section_leads_label: 'Prospection',
            menu_section_activity_label: 'Activité',
            menu_section_settings_label: 'Paramétrage',
            menu_section_settings2_label: 'Paramétrage #2',
            menu_section_settings3_label: 'Paramétrage #3',
            menu_section_settings4_label: 'Paramétrage #4',
            menu_section_medias_label: 'Médias',
            menu_section_employees_label: 'Portés',
            menu_section_payslips_label: 'Bulletins',
            menu_employees_label: 'Employés',
            menu_files_label: "Documents",
            menu_payslips_label: 'Bulletins',
            menu_eperiods_label: 'Périodes',
            menu_customers_label: 'Entreprises Clientes',
            menu_cinvoices_label: 'Factures',
            menu_cperiods_label: 'Périodes',
            menu_brokers_label: 'Apporteurs d\'affaires',
            menu_cpayments_label: 'Paiements',
            menu_dashboard_label: 'Tableau de Bord',
            menu_campaigns_label: 'Campagnes',
            menu_redirects_label: 'Redirections',
            menu_reminders_label: 'Rappels',
            menu_greminders_label: 'Rappels de collecte',
            menu_oreminders_label: "Rappels d'organisation",
            menu_preminders_label: 'Rappels de partenaire',
            menu_treminders_label: 'Rappels de tenant',
            menu_games_label: 'Collectes',
            menu_gimports_label: 'Gimports',
            menu_godfathers_label: 'Parrains',
            menu_sales_label: 'Ventes',
            menu_purchasers_label: 'Participants',
            menu_users_label: 'Utilisateurs',
            menu_integrations_label: 'Intégrations',
            menu_organizations_label: 'Organisations',
            menu_tickets_label: 'Tickets',
            menu_promises_label: 'Promesses',
            menu_simulations_label: 'Simulations',
            menu_tenants_label: 'Tenants',
            menu_keywords_label: 'Mots clefs',
            menu_beneficiaries_label: 'Bénéficiaires',
            menu_projects_label: 'Projets',
            menu_bunches_label: 'Lots',
            menu_sponsorings_label: 'Partenariats',
            menu_inputs_label: 'Champs',
            menu_packs_label: 'Packs',
            menu_offers_label: 'Offres',
            menu_modules_label: 'Modules',
            menu_orders_label: 'Commandes',
            menu_partners_label: 'Partenaires',
            menu_products_label: 'Produits',
            menu_gimages_label: 'Images de collecte',
            menu_oimages_label: "Images d'organisation",
            menu_gvideos_label: 'Vidéos de collecte',
            menu_ovideos_label: "Vidéos d'organisation",
            menu_gaudios_label: 'Audios de collecte',
            menu_oaudios_label: "Audios d'organisation",
            menu_jobs_label: "Jobs",
            menu_dynimages_label: 'Images dynamiques',
            menu_dynvideos_label: 'Vidéos dynamiques',
            menu_dynaudios_label: "Audios dynamiques",
            menu_sellers_label: "Vendeurs",
            menu_sellergroups_label: "Groupes de vendeurs",
            menu_draws_label: "Tirages",
            menu_winners_label: "Gagnants",
            menu_themes_label: "Thèmes",
            menu_notifications_label: "Notifications",
            menu_tags_label: "Tags",
            menu_features_label: "Features",
            menu_leads_label: "Apportés",
            menu_contracts_label: "Contrats",

            button_leads_label:'Voir les Leads',

            button_go_job_label: 'Aller au Job',
            button_game_management_label: 'Interface de gestion',
            button_abort_label: 'Abort',
            button_add_label: 'Ajouter',
            button_delete_label: 'Supprimer',
            button_display_label: 'Afficher',
            button_cancel_label: 'Annuler',
            button_clear_file_label: 'Effacer',
            button_confirm_label: 'Confirmer',
            button_delete_tenant_label: 'Supprimer',
            button_delete_employee_label: 'Supprimer',
            button_delete_eperiod_label:'Supprimer',
            button_delete_campaign_label: 'Supprimer',
            button_delete_customer_label: 'Supprimer',
            button_delete_cinvoice_label:'Supprimer',
            button_delete_contract_label:'Supprimer',
            button_delete_cperiod_label:'Supprimer',
            button_delete_cpayment_label:'Supprimer',
            button_delete_offer_label: 'Supprimer',
            button_delete_notification_label:'Supprimer',
            button_delete_sale_label: 'Supprimer',
            button_delete_game_label: 'Supprimer',
            button_delete_simulation_label: 'Supprimer',
            button_delete_ticket_label: 'Supprimer',
            button_delete_godfather_label: 'Supprimer',
            button_delete_organization_label: 'Supprimer',
            button_delete_user_label: 'Supprimer',
            button_delete_redirect_label: 'Supprimer',
            button_delete_keyword_label: 'Supprimer',
            button_delete_beneficiary_label: 'Supprimer',
            button_delete_broker_label:'Supprimer',
            button_delete_lead_label: 'Supprimer',
            button_delete_project_label: 'Supprimer',
            button_delete_payslip_label: 'Supprimer',
            button_delete_bunch_label: 'Supprimer',
            button_delete_sponsoring_label: 'Supprimer',
            button_delete_input_label: 'Supprimer',
            button_delete_pack_label: 'Supprimer',
            button_delete_integration_label: 'Supprimer',
            button_delete_expense_label:'Supprimer',
            button_draw_requested_label: 'Tirage',
            button_edit_label: 'Modifier',
            button_expenses_label:'Dépenses',
            button_eperiods_label:'Périodes',
            button_go_organization_label: 'Organisation',
            button_go_bunches_label: 'Lots',
            button_go_sales_label: 'Ventes',
            button_go_tickets_label: 'Tickets',
            button_go_projects_label: 'Projets',
            button_go_promises_label: 'Promesses',
            button_go_godfathers_label: 'Parrains',
            button_go_beneficiaries_label: 'Bénéficiaires',
            button_go_sponsorings_label: 'Sponsors',
            button_go_sellergroups_label: 'Groupes de vendeurs',
            button_go_sellers_label: 'Vendeurs',
            button_go_inputs_label: 'Champs',
            button_go_integrations_label: 'Intégrations',
            button_go_packs_label: 'Packs',
            button_go_keywords_label: 'Mots clés',
            button_go_draws_label: 'Tirages',
            button_go_winners_label: 'Gagnants',
            button_go_notifications_label: 'Notifications',
            button_go_gimports_label: 'Gimports',
            button_pause_label: 'Pause',
            button_regenerate_label: 'Regénérer',
            button_reset_label: 'Reset',
            button_resend_label: 'Renvoyer',
            button_start_label: 'Lancer',
            button_processing_label: 'En cours...',
            button_publishing_results_label: 'Publier les résultats',
            button_unpublishing_results_label: 'Dépublier les résultats',
            results_publishing_loading: 'Publication des résultats en cours...',
            button_notifying_participants_label: 'Notifier les participants',
            button_init_participants_notification_label: 'Initialiser le status de notification des participants',
            button_renotify_participants_label: 'Re-notifier les participants',
            participants_notifying_loading: 'Notification des participants en cours...',
            button_notifying_winning_participants_label: 'Notifier les gagnants',
            button_init_winning_participants_notification_label: 'Initialiser le status de notification des gagnants',
            button_renotify_winning_participants_label: 'Re-notifier les gagnants',
            winning_participants_notifying_loading: 'Notification des gagnants en cours...',
            button_notifying_loosing_participants_label: 'Notifier les perdants',
            button_init_loosing_participants_notification_label: 'Initialiser le status de notification des perdants',
            button_renotify_loosing_participants_label: 'Re-notifier les perdants',
            loosing_participants_notifying_loading: 'Notification des perdants en cours...',

            form_create_organization_submit_label: "Créer",
            form_create_tenant_submit_label: "Créer",
            form_create_user_submit_label: "Créer",

            form_edit_organization_submit_label: "Sauvegarder",

            form_edit_current_user_title: 'Modifier mes informations personnelles',
            form_edit_current_user_subtitle: 'Vous pouvez mettre à jour les données du compte ci-dessous',


            modal_delete_title: 'Confirmation de la suppression',
            modal_delete_text: 'Souhaitez-vous vraiment supprimer cet élément ?',

            modal_confirm_delete_broker_title: 'Confirmation de la suppression',
            modal_confirm_delete_broker_text: 'Souhaitez-vous vraiment supprimer cet Apporteur d\'affaire',
            modal_confirm_delete_employee_title: 'Confirmation de la suppression',
            modal_confirm_delete_employee_text: 'Souhaitez-vous vraiment supprimer cet employé ?',

            modal_confirm_delete_expense_title:'Confirmation de la suppression',
            modal_confirm_delete_expense_text: 'Souhaitez-vous vraiment supprimer cette dépense',

            modal_confirm_delete_eperiod_title:'Confirmation de la suppression',
            modal_confirm_delete_eperiod_text:'Souhaitez vous vraiment supprimer cette période ?',

            modal_confirm_delete_payslip_title: 'Confirmation de la suppression',
            modal_confirm_delete_payslip_text: 'Souhaitez-vous vraiment supprimer ce Bulletin ?',

            modal_confirm_delete_tenant_title: 'Confirmation de la suppression',
            modal_confirm_delete_tenant_text: 'Souhaitez-vous vraiment supprimer ce tenant ?',

            modal_confirm_delete_campaign_title: "Suppression de la campagne",
            modal_confirm_delete_campaign_text: "Souhaitez-vous vraiment supprimer cette campagne ?",

            modal_confirm_delete_cinvoice_title: "Confirmation de la suppression",
            modal_confirm_delete_cinvoice_text:"Souhaitez-vous vraiment supprimer cette facture ?",

            modal_confirm_delete_customer_title: "Confirmation de la suppression",
            modal_confirm_delete_customer_text: "Souhaitez-vous vraiment supprimer cette entreprise cliente ? ",

            modal_confirm_delete_contract_title: "Confirmation de la suppression",
            modal_confirm_delete_contract_text: "Souhaitez-vous vraiment supprimer ce contrat ? ",

            modal_confirm_delete_cpayment_title: "Confirmation de  la suppression",
            modal_confirm_delete_cpayment_text: "Souhaitez-vous vraiment supprimer ce Paiement ?",

            modal_confirm_delete_cperiod_title: "Confirmation de la suppression",
            modal_confirm_delete_cperiod_text:"Souhaitez-vous vraiment supprimer cette Période ?",

            modal_confirm_delete_offer_title: 'Confirmation de la suppression',
            modal_confirm_delete_offer_text: 'Souhaitez-vous vraiment supprimer cette offre ?',

            modal_confirm_delete_sale_title: 'Confirmation de la suppression',
            modal_confirm_delete_sale_text: 'Souhaitez-vous vraiment supprimer cette vente ?',

            modal_confirm_delete_simulation_title: 'Confirmation de la suppression',
            modal_confirm_delete_simulation_text: 'Souhaitez-vous vraiment supprimer cette simulation ?',

            modal_confirm_delete_game_title: 'Confirmation de la suppression',
            modal_confirm_delete_game_text: 'Souhaitez-vous vraiment supprimer cette collecte ?',

            modal_confirm_delete_lead_title: 'Confirmation de la suppression',
            modal_confirm_delete_lead_text: 'Souhaitez-vous vraiment supprimer ce candidat ?',

            modal_confirm_delete_ticket_title: 'Confirmation de la suppression',
            modal_confirm_delete_ticket_text: 'Souhaitez-vous vraiment supprimer ce ticket ?',

            modal_confirm_delete_godfather_title: 'Confirmation de la suppression',
            modal_confirm_delete_godfather_text: 'Souhaitez-vous vraiment supprimer ce parrain ?',

            modal_confirm_delete_notification_title:'Confirmation de la suppression',
            modal_confirm_delete_notification_text:'Souhaitez vous vraiment supprimer cette notification ?',

            modal_confirm_delete_organization_title: 'Confirmation de la suppression',
            modal_confirm_delete_organization_text: 'Souhaitez-vous vraiment supprimer cette organisation ?',

            modal_confirm_delete_user_title: 'Confirmation de la suppression',
            modal_confirm_delete_user_text: 'Souhaitez-vous vraiment supprimer cet utilisateur ?',

            modal_confirm_delete_redirect_title: 'Confirmation de la suppression',
            modal_confirm_delete_redirect_text: 'Souhaitez-vous vraiment supprimer cette redirection ?',

            modal_confirm_delete_keyword_title: 'Confirmation de la suppression',
            modal_confirm_delete_keyword_text: 'Souhaitez-vous vraiment supprimer ce mot-clé ?',

            modal_confirm_delete_beneficiary_title: 'Confirmation de la suppression',
            modal_confirm_delete_beneficiary_text: 'Souhaitez-vous vraiment supprimer ce bénéficiaire ?',

            modal_confirm_delete_project_title: 'Confirmation de la suppression',
            modal_confirm_delete_project_text: 'Souhaitez-vous vraiment supprimer ce projet ?',

            modal_confirm_delete_bunch_title: 'Confirmation de la suppression',
            modal_confirm_delete_bunch_text: 'Souhaitez-vous vraiment supprimer ce lot ?',

            modal_confirm_delete_sponsoring_title: 'Confirmation de la suppression',
            modal_confirm_delete_sponsoring_text: 'Souhaitez-vous vraiment supprimer ce partenariat ?',

            modal_confirm_delete_input_title: 'Confirmation de la suppression',
            modal_confirm_delete_input_text: 'Souhaitez-vous vraiment supprimer ce champ ?',

            modal_confirm_delete_pack_title: 'Confirmation de la suppression',
            modal_confirm_delete_pack_text: 'Souhaitez-vous vraiment supprimer ce pack ?',

            modal_confirm_delete_integration_title: 'Confirmation de la suppression',
            modal_confirm_delete_integration_text: 'Souhaitez-vous vraiment supprimer cette intégration ?',

            user_menu_profile_label: 'Mon compte',

            tab_employee_infos_title: 'Infos',
            tab_employee_eperiods_title:'Périodes',
            tab_employee_expenses_title:'Dépenses',
            tab_broker_infos_title: 'Infos',
            tab_broker_leads_title:'Leads',
            tab_organization_infos_title: 'Infos',
            tab_organization_users_title: 'Utilisateurs',
            tab_organization_games_title: 'Collectes',
            tab_organization_tickets_title: 'Tickets',
            tab_organization_sales_title: 'Ventes',
            tab_organization_godfathers_title: 'Parrains',
            tab_organization_integrations_title: 'Intégrations',
            tab_draw_infos_title: 'Infos',
            tab_draw_winners_title: 'Gagnants',
            tab_job_infos_title: 'Infos',
            tab_job_data_title: 'Data',
            tab_job_report_title: 'Report',
            tab_job_progress_title: 'Progression',

            no_winners: 'Pas de gagnants.',
            content_not_generated: 'Content not generated.',
        }
    },
}
export default translations
