const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'employees', priority: 50},
    },
    new: {
        attributes: [
           'employee', 'tenant', 'type','name', 'mainFile'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$employee','!$tenant','$type!','$name!', '$mainFile!'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'employee', 'tenant', 'name','type', 'mainFile'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant', '$employee','$name', '$mainFile','type'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id','tenant','employee', 'name', 'type', ['mainFile']
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-toolbox',
        columns: [
            {id: 'name', label: 'column_name_label'},
            {id: 'employee', label: 'column_employee_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'mainFile', label: 'column_mainFile_label'},
            {id: 'tenant', label: 'column_tenant_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;