// noinspection JSUnusedGlobalSymbols

export const LOGIN = (gql: any) => gql`
    mutation login($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const REFRESH_LOGIN = (gql: any) => gql`
    mutation refreshLogin($data: RefreshAuthTokenInput!) {
        refreshAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const CREATE_NOTIFICATION = (gql: any) => gql`
    mutation createNotification($data: CreateNotificationInput!) {
        createNotification(data: $data) {
            id
        }
    }
`
export const CREATE_ORGANIZATION = (gql: any) => gql`
    mutation createOrganization($data: CreateOrganizationInput!) {
        createOrganization(data: $data) {
            id
        }
    }
`
export const CREATE_BROKER = (gql: any) => gql`
    mutation createBroker($data: CreateBrokerInput!) {
        createBroker(data: $data) {
            id
        }
    }
`
export const CREATE_LEAD = (gql: any) => gql`
    mutation createLead($data: CreateLeadInput!) {
        createLead(data: $data) {
            id
        }
    }
`
export const CREATE_TENANT = (gql: any) => gql`
    mutation createTenant($data: CreateTenantInput!) {
        createTenant(data: $data) {
            id
            code
        }
    }
`
export const CREATE_USER = (gql: any) => gql`
    mutation createUser($data: CreateUserInput!) {
        createUser(data: $data) {
            id
        }
    }
`;
export const CREATE_EMPLOYEE = (gql: any) => gql`
    mutation createEmployee($data: CreateEmployeeInput!) {
        createEmployee(data: $data) {
            id
        }
    }
`;
export const CREATE_EPERIOD = (gql: any) => gql`
    mutation createEperiod($data: CreateEperiodInput!) {
        createEperiod(data: $data) {
            id
        }
    }
`;
export const CREATE_FILE = (gql: any) => gql`
    mutation createFile($data: CreateFileInput!) {
        createFile(data: $data) {
            id
        }
    }
`;
export const CREATE_CUSTOMER = (gql: any) => gql`
    mutation createCustomer($data: CreateCustomerInput!) {
        createCustomer(data: $data) {
            id
        }
    }
`;
export const CREATE_CONTRACT = (gql: any) => gql`
    mutation createContract($data: CreateContractInput!) {
        createContract(data: $data) {
            id
        }
    }
`;
export const CREATE_SIMULATION = (gql: any) => gql`
    mutation createSimulation($data: CreateSimulationInput!) {
        createSimulation(data: $data) {
            id
        }
    }
`;
export const CREATE_PROJECT = (gql: any) => gql`
    mutation createProject($data: CreateProjectInput!) {
        createProject(data: $data) {
            id
        }
    }
`;
export const CREATE_CINVOICE = (gql: any) => gql`
    mutation createCinvoice($data: CreateCinvoiceInput!) {
        createCinvoice(data: $data) {
            id
        }
    }
`;
export const CREATE_CPERIOD = (gql: any) => gql`
    mutation createCperiod($data: CreateCperiodInput!) {
        createCperiod(data: $data) {
            id
        }
    }
`;
export const CREATE_CPAYMENT = (gql: any) => gql`
    mutation createCpayment($data: CreateCpaymentInput!) {
        createCpayment(data: $data) {
            id
        }
    }
`;
export const CREATE_PAYSLIP = (gql: any) => gql`
    mutation createPayslip($data: CreatePayslipInput!) {
        createPayslip(data: $data) {
            id
        }
    }
`;

export const GET_CURRENT_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;
export const GET_NOTIFICATION = (gql: any) => gql`
    query getNotification($id: ID!) {
        getNotification(id: $id) {
            id
            createdAt
            status
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            template
            recipient
            sender
            variables
            type
            locale
            country
            subject
            attachments
            bodyFile {
                available
                url
            }
            bodyTextFile {
                available
                url
            }
        }
    }
`;
export const GET_ORGANIZATION = (gql: any) => gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            id
            name
            fullName
            shortName
            legalName
            createdAt
            updatedAt
            status
            code
            locale
            country
            city
            street
            zipCode
            overline
            complement
            state
            owner
            ownerEmail
            ownerFirstName
            ownerLastName
            tenant
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
                
            }
            bannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
        }
    }
`;
export const GET_TENANT = (gql: any) => gql`
    query getTenant($id: ID!) {
        getTenant(id: $id) {
            id
            code
            name
        }
    }
`;
export const GET_USER = (gql: any) => gql`
    query getUser($id: ID!) {
        getUser(id: $id) {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
            tenant,
            tenantCode,
            tenantName,
            avatarImage {
                url
            }
        }
    }
`;
export const GET_EMPLOYEE = (gql: any) => gql`
    query getEmployee($id: ID!) {
        getEmployee(id: $id) {
            id
            code
            tenant
            email
            firstName
            externalId
            lastName
            createdAt
            updatedAt
            status
            statAccountAmount
            
        }
    }
`;

export const GET_EXPENSE = (gql: any) => gql`
    query getExpense($id: ID!) {
        getExpense(id: $id) {
            id
            tenant
            cost 
            name
            mooncardId
            date
            vatCost
            vatPercent
        }
    }
`;

export const GET_FILE = (gql: any) => gql`
    query getFile($id: ID!) {
        getFile(id: $id) {
            id
            tenant
            employee
            name
            type
            mainFile {
                url
                available
                size
            }
        }
    }
`;

export const GET_BROKER = (gql: any) => gql`
    query getBroker($id: ID!) {
        getBroker(id: $id) {
            id
            tenant
            tenantCode
            tenantName
            code
            firstName
            lastName
            email
            url
            phone
            publicToken
            privateToken
            createdAt
            updatedAt
            status
        }
    }
`;
export const GET_LEAD = (gql: any) => gql`
    query getLead($id: ID!) {
        getLead(id: $id) {
            broker
            brokerCode
            brokerFirstName
            brokerLastName
            code
            createdAt
            email
            firstName
            id
            lastName
            phone
            status
            updatedAt
        }
    }
`;
export const GET_EPERIOD = (gql: any) => gql`
    query getEperiod($id: ID!) {
        getEperiod(id: $id) {
            tenant
            customerName
            employee
            project
            externalId
            dateStart
            dateEnd
            workedDay
            periodPercent
            periodTurnover
            internalFees
            periodExpense
            periodChargeProvided
            baseAccount
            taxedSalary
            employerContributions
            employeeContributions
            salary
            peeAmount
            perAmount
            incomeTaxes
            locked
            commercialFee
            craUrl
            payslipUrl
            
        }
    }
`;
export const GET_PAYSLIP = (gql: any) => gql`
    query getPayslip($id: ID!) {
        getPayslip(id: $id) {
            id
            code
            tenant
            tenantName
            employee
            employeeEmail
            employeeFirstName
            employeeLastName
            employeePhone
            externalId
            mainFile {
                available
                url
            }
        }
    }
`;

export const GET_CUSTOMER = (gql: any) => gql`
    query getCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            code
            tenant
            name
        }
    }
`;
export const GET_CONTRACT = (gql: any) => gql`
    query getContract($id: ID!) {
        getContract(id: $id) {
            id
            code
            tenant
            employee
            customer
            code
            rate
            status
            project
            locked
            billingMode
            is_amendment
        }
    }
`;
export const GET_SIMULATION = (gql: any) => gql`
    query getSimulation($id: ID!) {
        getSimulation(id: $id) {
            id
            type
            result
            values
            tenant
            ownerEmail
            ownerName
            ownerFirstName
            date
            status
            failureDetails
        }
    }
`;
export const GET_PROJECT = (gql: any) => gql`
    query getProject($id: ID!) {
        getProject(id: $id) {
            id
            tenant
            customer
            code
        }
    }
`;
export const GET_CINVOICE = (gql: any) => gql`
    query getCinvoice($id: ID!) {
        getCinvoice(id: $id) {
            id
            project
            customer
            externalId
            tenant
        }
    }
`;
export const GET_CPERIOD = (gql: any) => gql`
    query getCperiod($id: ID!) {
        getCperiod(id: $id) {
            id
            createdAt
            updatedAt
            project
            customer 
            tenant 
          
        }
    }
`;
export const GET_CPAYMENT = (gql: any) => gql`
    query getCpayment($id: ID!) {
        getCpayment(id: $id) {
            id
            createdAt
            updatedAt
            project
            customer 
            tenant 
          
        }
    }
`;
export const GET_ORGANIZATION_BY_CODE = (gql: any) => gql`
    query getOrganizationByCode($code: String!) {
        getOrganizationByCode(code: $code) {
            id
            code
            name
        }
    }
`;

export const FIND_NOTIFICATIONS = (gql: any) => gql`
    query findNotifications($offset: String, $limit: Int) {
        findNotifications(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const FIND_ORGANIZATIONS = (gql: any) => gql`
    query findOrganizations($offset: String, $limit: Int) {
        findOrganizations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                tenant
                tenantCode
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const FIND_TENANTS = (gql: any) => gql`
    query findTenants($offset: String, $limit: Int) {
        findTenants(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
            }
        }
    }
`;
export const FIND_USERS = (gql: any) => gql`
    query findUsers($offset: String, $limit: Int) {
        findUsers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                tenantName,
                tenantCode,
                tenant,
                role
                avatarImage {
                    url
                }
            }
        }
    }
`;
export const FIND_EMPLOYEES = (gql: any) => gql`
    query findEmployees($offset: String, $limit: Int) {
        findEmployees(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                lastName
                firstName
                externalId
                email
                createdAt
                updatedAt
                status
                tenant
                statAccountAmount
            }
        }
    }
`;
export const FIND_EPERIODS = (gql: any) => gql`
    query findEperiods($offset: String, $limit: Int) {
        findEperiods(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                customerName
                tenant
                tenantName
                employee
                project
                externalId
                contract
                dateStart
                dateEnd
                workedDay
                periodPercent
                periodTurnover
                internalFees
                periodExpense
                periodChargeProvided
                baseAccount
                taxedSalary
                employerContributions
                employeeContributions
                salary
                peeAmount
                perAmount
                incomeTaxes
                locked
                commercialFee
                craUrl
                payslipUrl
                
            }
        }
    }
`;

export const FIND_EMPLOYEE_EPERIODS = (gql: any) => gql`
    query findEmployeeEperiods($id:String!, $offset: String, $limit: Int) {
        findEmployeeEperiods:
         findEperiodsByEmployee(employee: $id ,offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantName
                employee
                project
                externalId
                contract
                dateStart
                dateEnd
                workedDay
                periodPercent
                periodTurnover
                internalFees
                periodExpense
                periodChargeProvided
                baseAccount
                taxedSalary
                employerContributions
                employeeContributions
                salary
                peeAmount
                perAmount
                incomeTaxes
                locked
                commercialFee
                craUrl
                payslipUrl
                
            }
        }
    }
`;

export const FIND_EMPLOYEE_EXPENSES = (gql: any) => gql`
    query findEmployeeExpenses($id: String! $offset: String $limit: Int) {
        findEmployeeExpenses: findExpensesByEmployee(employee: $id, offset: $offset, limit: $limit) {
            cursor 
            count
            items {
                id
                tenant
                name
                mooncardId
                cost
                date
                vatCost
                vatPercent
            }
        }
    }

`;

export const FIND_FILES = (gql: any) => gql`
    query findFiles($offset: String $limit: Int) {
        findFiles(offset: $offset, limit: $limit) {
            cursor 
            count
            items {
                id
                tenant
                employee
                name
                type
                mainFile {
                    url
                    available
                    size
                }
            }
        }
    }

`;


export const FIND_CUSTOMERS = (gql: any) => gql`
    query findCustomers($offset: String, $limit: Int) {
        findCustomers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                externalId
            }
        }
    }
`;
export const FIND_CONTRACTS = (gql: any) => gql`
    query findContracts($offset: String, $limit: Int) {
        findContracts(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                tenant
                tenantName
                employee
                employeeFirstName
                employeeLastName
                customer
                customerName
                rate
                project
                locked
                status
                billingMode
                is_amendment
                externalId
                dateEnd
                dateStart
            }
        }
    }
`;
export const FIND_SIMULATIONS = (gql: any) => gql`
    query findSimulations($offset: String, $limit: Int) {
        findSimulations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                result
                values
                tenant
                tenantCode
                tenantName
                ownerEmail
                ownerName
                ownerFirstName
                createdAt
                status
            }
        }
    }
`;
export const FIND_PAYSLIPS = (gql: any) => gql`
    query findPayslip($offset: String, $limit: Int) {
        findPayslips(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantName
                code
                employee
                employeeEmail
                employeeFirstName
                employeeLastName
                employeePhone
                externalId
                mainFile {
                    url
                    available
                }
            }
        }
    }
`;
export const FIND_CINVOICES = (gql: any) => gql`
    query findCinvoices($offset: String, $limit: Int) {
        findCinvoices(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                customer
                customerCode
                customerName
                project
                projectCode
                projectName
                price
                description
                externalId
            }
        }
    }
`;
export const FIND_CPERIODS = (gql: any) => gql`
    query findCperiods($offset: String, $limit: Int) {
        findCperiods(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                customer
                customerCode
                customerName 
                project 
                projectCode
                projectName
                tenant 
                tenantCode
                tenantName
               
            }
        }
    }
`;
export const FIND_CPAYMENTS = (gql: any) => gql`
    query findCpayments($offset: String, $limit: Int) {
        findCpayments(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                customer
                customerName
                customerCode
                project
                projectName
                projectCode
                tenant 
                tenantName
                tenantCode
               
            }
        }
    }
`;
export const FIND_PROJECTS = (gql: any) => gql`
    query findProjects($offset: String, $limit: Int) {
        findProjects(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantName
                customer
                customerName
                name
                code
            }
        }
    }
`;
export const FIND_BROKERS = (gql: any) => gql`
    query findBrokers($offset: String, $limit: Int) {
        findBrokers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode 
                tenantName 
                code 
                firstName 
                lastName
                email 
                phone
                url
                publicToken
                privateToken
                createdAt 
                updatedAt 
                status
            }
        }
    }
`;

export const FIND_BROKER_LEADS = (gql: any) => gql`
    query findBrokerLeads($id:String $offset: String, $limit: Int) {
        findBrokerLeads: findLeadsByBroker(brokerId: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                firstName
                lastName
                email
                phone
                status
                createdAt
                updatedAt
                code
            }
        }
    }
`;

export const FIND_LEADS = (gql: any) => gql`
    query findLeads($offset: String, $limit: Int) {
        findLeads(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                broker
                brokerCode
                brokerFirstName
                brokerLastName
                code
                firstName
                lastName
                email
                phone
                createdAt
                updatedAt
                status
            }
        }
    }
`;

export const UPDATE_NOTIFICATION = (gql: any) => gql`
    mutation updateNotification($id: ID!, $data: UpdateNotificationInput!) {
        updateNotification(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_ORGANIZATION = (gql: any) => gql`
    mutation updateOrganization($id: ID!, $data: UpdateOrganizationInput!) {
        updateOrganization(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TENANT = (gql: any) => gql`
    mutation updateTenant($id: ID!, $data: UpdateTenantInput!) {
        updateTenant(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_USER = (gql: any) => gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
        updateUser(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_EMPLOYEE = (gql: any) => gql`
    mutation updateEmployee($id: ID!, $data: UpdateEmployeeInput!) {
        updateEmployee(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_EPERIOD = (gql: any) => gql`
    mutation updateEperiod($id: ID!, $data: UpdateEperiodInput!) {
        updateEperiod(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_FILE= (gql: any) => gql`
    mutation updateFile($id: ID!, $data: UpdateFileInput!) {
        updateFile(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CUSTOMER = (gql: any) => gql`
    mutation updateCustomer($id: ID!, $data: UpdateCustomerInput!) {
        updateCustomer(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CONTRACT = (gql: any) => gql`
    mutation updateContract($id: ID!, $data: UpdateContractInput!) {
        updateContract(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SIMULATION = (gql: any) => gql`
    mutation updateSimulation($id: ID!, $data: UpdateSimulationInput!) {
        updateSimulation(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PROJECT = (gql: any) => gql`
    mutation updateProject($id: ID!, $data: UpdateProjectInput!) {
        updateProject(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CINVOICE = (gql: any) => gql`
    mutation updateCinvoice($id: ID!, $data: UpdateCinvoiceInput!) {
        updateCinvoice(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CPERIOD = (gql: any) => gql`
    mutation updateCperiod($id: ID!, $data: UpdateCperiodInput!) {
        updateCperiod(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CPAYMENT = (gql: any) => gql`
    mutation updateCpayment($id: ID!, $data: UpdateCpaymentInput!) {
        updateCpayment(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PAYSLIP = (gql: any) => gql`
    mutation updatePayslip($id: ID!, $data: UpdatePayslipInput!) {
        updatePayslip(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_BROKER = (gql: any) => gql`
    mutation updateBroker($id: ID!, $data: UpdateBrokerInput!) {
        updateBroker(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_LEAD = (gql: any) => gql`
    mutation updateLead($id: ID!, $data: UpdateLeadInput!) {
        updateLead(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_NOTIFICATION = (gql: any) => gql`
    mutation deleteNotification($id: ID!) {
        deleteNotification(id: $id) {
            id
        }
    }
`;
export const DELETE_ORGANIZATION = (gql: any) => gql`
    mutation deleteOrganization($id: ID!) {
        deleteOrganization(id: $id) {
            id
        }
    }
`;
export const DELETE_TENANT = (gql: any) => gql`
    mutation deleteTenant($id: ID!) {
        deleteTenant(id: $id) {
            id
        }
    }
`;
export const DELETE_USER = (gql: any) => gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;
export const DELETE_EMPLOYEE = (gql: any) => gql`
    mutation deleteEmployee($id: ID!) {
        deleteEmployee(id: $id) {
            id
        }
    }
`;
export const DELETE_EPERIOD = (gql: any) => gql`
    mutation deleteEperiod($id: ID!) {
        deleteEperiod(id: $id) {
            id
        }
    }
`;

export const DELETE_EXPENSE = (gql: any) => gql`
    mutation deleteExpense($id: ID!) {
        deleteExpense(id: $id) {
            id
        }
    }
`;

export const DELETE_FILE = (gql: any) => gql`
    mutation deleteFile($id: ID!) {
        deleteFile(id: $id) {
            id
        }
    }
`;

export const DELETE_CUSTOMER = (gql: any) => gql`
    mutation deleteCustomer($id: ID!) {
        deleteCustomer(id: $id) {
            id
        }
    }
`;
export const DELETE_CONTRACT = (gql: any) => gql`
    mutation deleteContract($id: ID!) {
        deleteContract(id: $id) {
            id
        }
    }
`;
export const DELETE_SIMULATION = (gql: any) => gql`
    mutation deleteSimulation($id: ID!) {
        deleteSimulation(id: $id) {
            id
        }
    }
`;
export const DELETE_PROJECT = (gql: any) => gql`
    mutation deleteProject($id: ID!) {
        deleteProject(id: $id) {
            id
        }
    }
`;
export const DELETE_CINVOICE = (gql: any) => gql`
    mutation deleteCinvoice($id: ID!) {
        deleteCinvoice(id: $id) {
            id
        }
    }
`;
export const DELETE_CPERIOD = (gql: any) => gql`
    mutation deleteCperiod($id: ID!) {
        deleteCperiod(id: $id) {
            id
        }
    }
`;
export const DELETE_CPAYMENT = (gql: any) => gql`
    mutation deleteCpayment($id: ID!) {
        deleteCpayment(id: $id) {
            id
        }
    }
`;
export const DELETE_PAYSLIP = (gql: any) => gql`
    mutation deletePayslip($id: ID!) {
        deletePayslip(id: $id) {
            id
        }
    }
`;
export const DELETE_BROKER = (gql: any) => gql`
    mutation deleteBroker($id: ID!) {
        deleteBroker(id: $id) {
            id
        }
    }
`;
export const DELETE_LEAD = (gql: any) => gql`
    mutation deleteLead($id: ID!) {
        deleteLead(id: $id) {
            id
        }
    }
`;
