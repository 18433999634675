const importers = {
    screen: [({path}: any) => import(`./screens/${path}Screen`), ['home','denied']],
    body: [({path}: any) => import(`./bodies/${path}Body`),
        [
            'notification',
        ],
    ],
    tab: [
        ({path}: any) => import(`./tabs/${path}Tab`),
        [
            'organization/infos', 'organization/users',
            'broker/infos','broker/leads',
            'employee/expenses','employee/infos','employee/eperiods'
        ],
    ],
    toolbar: [
        ({path}: any) => import(`./toolbars/${path}Toolbar`),
        [
            'notification', 'simulation','broker','employee'
        ],
    ],
};

export default importers;